import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import axios from 'axios';
import store from '@/store';
import {
  required
} from "vuelidate/lib/validators";

export default {
    name: "bookList",
    components: {
        MainLayout,
        Select2Multiple,
        Loading,
        CONFIG,
        AdvancedLaravelVuePaginate
    },

    data() {
        return {
            bookData: {},
            searchData: {
                title: '',
                author: '',
                campus_name: '',
                category_name: '',
                type: '',
                file_name: '',
            },
            categoryArr: null,
            typeArr: [
                { id: 0, text: 'Select Type' },
                { id: 1, text: 'Physical Book' },
                { id: 2, text: 'Ebook' },
            ],
            submitted: false,
            isLoading: false,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            adminNo: store.state.user.admin_no,
            campusId: store.state.user.campus_id,
            bookTypeOptions: CONFIG.BOOK_TYPE,
            bookFile: '',
            bookFileName: '',
            invalidFile: false,
            disabled: false,
        };
    },

    validations: {
        bookFile: {
          required
        }
      },
    methods: {
        getBook(page = 1) {
            const campusId = this.$store.state.user.campus_id;
            axios.get(`book/list?page=${page}&campusId=${campusId}`)
                .then(res => {
                    this.bookData = res.data;
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.delete(id);
            } else {
                console.log("no");
            }
        },

        delete(id) {
            this.isLoading = true;
            axios.delete(`book/delete/${id}`)
                .then(res => {
                    if (res.data.success) {
                        this.getBook();
                        this.$notification.success("Book deleted Successfully.", {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    } else {
                        this.disabled = false;
                        this.$notification.error(res.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        search(page = 1) {
            this.submitted = true;
            this.isLoading = true;
            axios.get('book/search?page=' + page, {
                params: {
                    title: this.searchData.title || '',
                    author: this.searchData.author || '',
                    campus_name: this.searchData.campus_name || '',
                    campus_id: this.campusId || '',
                    category_id: this.searchData.category_name || '',
                    type: this.searchData.type || '',
                    file_name: this.searchData.file_name || '',
                }
            })
                .then(res => {
                    this.isLoading = false;
                    if (res.data) {
                        this.bookData = res.data;
                    }
                }).catch(error => {

                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        reset() {
            this.searchData.title = '';
            this.searchData.author = '';
            this.searchData.campus_name = '';
            this.searchData.category_name = '';
            this.searchData.type = '';
            this.searchData.file_name = '';
        },

        excelDownload() {
            this.isLoading = true;
            axios.get('/book/excelDownload', {
                params: {
                    title: this.searchData.title || '',
                    author: this.searchData.author || '',
                    campus_name: this.searchData.campus_name || '',
                    category_name: this.searchData.category_name || '',
                    campus_id: this.campusId || '',
                    category_id: this.searchData.category_name || '',
                    type: this.searchData.type || '',
                    file_name: this.searchData.file_name || '',
                },
                responseType: 'arraybuffer'
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let date = new Date();
                    fileLink.setAttribute('download', `book_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getCategoryList() {
            axios.get('category/listforchoose')
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Category'
                    }];
                    this.categoryArr = $defaultSelectOption.concat(
                        res.data.map(category => ({
                            id: category.id,
                            text: category.name
                        }))
                    );
                    this.isLoading = false;
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        templateDownload() {
            this.isLoading = true;
            axios.get('book/template/download', {
                params: {
                },
                responseType: 'arraybuffer'
            })
            .then(response => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'book_import_template.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                this.isLoading = false;
            }).catch(error => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
        },

        chooseFile() {
            this.$refs.fileUploader.value = '';
            document.getElementById('book-upload').click();
        },

        onBookFileChange() {
            this.invalidFile = false;
            let selectedFile = document.getElementById('book-upload').files;
            if (selectedFile.length) {
                let file = selectedFile[0];
                if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    this.bookFileName = file.name;
                    this.bookFile = file;
                } else {
                    this.invalidFile = true;
                }
            }
            this.disabled = false;
        },
  
        importFile() {
            this.submitted = true;
            this.disabled = true;
            if (this.$v.$invalid) {
              this.disabled = false;
              return;
            } else {
                this.isLoading = true;
                let data = new FormData();
                data.append('book_xlsx', this.bookFile);
                data.append('campus_id', this.$store.state.user.campus_id);
                data.append('created_id', this.$store.state.user.id);
                axios.post("book/import", data, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    if (response.data.success == true) {
                        this.$notification.success(response.data.message, {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.disabled = false;
                        this.getBook();
                        this.clearFile();
                    } else {
                        this.isLoading = false;
                        this.disabled = false;
                        alert(response.data.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
            }
        },

        clearFile() {
            this.bookFile = '';
            this.bookFileName = '';
            document.getElementById('book-upload').value = '';
            this.submitted = false;
            this.disabled = false;
            this.invalidFile = false;
        },
    },

    mounted() {
        this.isLoading = true;
        this.getBook();
        this.getCategoryList();
    },
};

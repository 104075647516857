import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import CONFIG from "@/assets/js/config.js";
import { required, maxLength, helpers, requiredIf } from "vuelidate/lib/validators";
import { integer } from 'vuelidate/lib/validators';
import axios from "axios";
import store from '@/store';

const inputTypeVal = Object.entries(CONFIG.BOOK_TYPE)
    .filter(([key]) => key !== 'FILE')
    .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});

const inInputType = helpers.withParams(
    { type: "inInputType" },
    value => {
        return Object.values(inputTypeVal).includes(Number(value));
    }
);

const inputTypeArr = Object.entries(CONFIG.BOOK_TYPE_VALUE)
    .filter(([key]) => key !== 'FILE')
    .map(([key, value]) => ({ id: Number(value), text: key }));

export default {
    name: "bookCreate",
    components: {
        MainLayout,
        Select2Multiple,
    },
    data() {
        return {
            inputTypeVal: inputTypeVal,
            inputTypeArr: inputTypeArr,
            campusName: [],
            bookData: {
                campus_id: this.$store.state.user.campus_id,
                category_id: '',
                title: '',
                description: '',
                author: '',
                type: '',
                file_name: '',
                status: 0,
                quantity: '',
            },
            categoryArr: null,
            submitted: false,
            adminNo: store.state.user.admin_no,
            campusId: store.state.user.campus_master.campus_code,
            invalidFile: false,
            disabled: false,
            config: CONFIG,
            isSubmitting: false,
            fileRequired: true,
            selectedType: 3,
            bookTypeArr: CONFIG.BOOK_TYPE_VALUE
        };
    },
    validations: {
        bookData: {
            campus_id: { required, integer },
            category_id: { required, integer },
            title: { required, maxLength: maxLength(255) },
            author: { required, maxLength: maxLength(255) },
            type: { required, inInputType },
            inInputType,
            file_name: { maxLength: maxLength(255) },
            quantity: {
                integer,
                required: requiredIf(function () {
                    return this.bookData.type == 1;
                })
            },
        },
    },
    computed: {
        showFileNameField() {
            return Number(this.bookData.type) === 2;
        },
        showQtyField() {
            return Number(this.bookData.type) === 1;
        },
        isValidCategory() {
            if (!this.categoryArr || this.categoryArr.length === 0) return false;
            return this.categoryArr.some(
                (category) => Number(category.id) === Number(this.bookData.category_id)
            );
        },
    },

    methods: {
        isRoleOther() {
            return this.bookData.role === CONFIG.ROLE.OTHER;
        },
        getAdminRole() {
            axios.get('getAdminRole')
                .then(res => {
                    this.adminRole = res.data;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        campusNameAction() {
            if (this.adminNo === '0001') {
                axios.get('campus/campusName')
                    .then(res => {
                        this.campusName = res.data;
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                        this.isLoading = false;
                    });
            } else {
                this.campusName = this.$store.state.user.campus_master.campus_name;
                this.bookData.campus_id = this.$store.state.user.campus_master.id;
            }
        },
        changeEvent(val, fieldName) {
            this.$set(this, fieldName, val);

            if (fieldName == "selectedType") {
                if (val == this.bookTypeArr.Ebook) {
                    this.fileRequired = true;
                } else {
                    this.fileRequired = false;
                }
            }
        },
        checkFile() {
            if (Number(this.bookData.type) === 1) {
                this.bookData.file_name = '';
            }
        },
        async create() {
            if (this.isSubmitting) return;
            this.isSubmitting = true;
            this.isLoading = true;
            if (this.disabled) return;
            this.submitted = true;
            this.$v.$touch();

            if (!this.validateForm()) {
                this.isSubmitting = false;
                this.isLoading = false;
                return;
            }
            if (Number(this.bookData.type) == 2 && this.$refs.fileUploader.files.length == 0) {
                this.fileRequired = true;
                this.isSubmitting = false;
                this.isLoading = false;
                return;
            } else {
                this.fileRequired = false;
            }
            this.disabled = true;

            const formData = new FormData();
            formData.append('campus_id', this.bookData.campus_id);
            formData.append('category_id', this.bookData.category_id);
            formData.append('title', this.bookData.title);
            formData.append('description', this.bookData.description);
            formData.append('author', this.bookData.author);
            formData.append('type', this.bookData.type);
            formData.append('quantity', this.bookData.quantity);

            if (Number(this.bookData.type) === 2 && this.$refs.fileUploader.files.length) {
                formData.append('file_name', this.$refs.fileUploader.files[0]);
                formData.append('name', this.$refs.fileUploader.files[0].name);
            }

            try {
                const res = await axios.post('book/create', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (res.data.success) {
                    this.$router.push({ name: 'bookList' }).catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            console.error(err);
                        }
                    });
                    this.$notification.success('Book Created Successfully', {
                        timer: 3,
                        position: 'bottomCenter',
                    });
                } else {
                    this.$notification.error(res.data.message, {
                        timer: 3,
                        position: 'bottomCenter',
                    });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                } else if (error.response.status === 422) {
                    this.$notification.error(
                        `Validation Error: ${error.response.data.message}`,
                        {
                            timer: 5,
                            position: 'bottomCenter',
                        }
                    );
                }
            } finally {
                this.isSubmitting = false;
                this.disabled = false;
                this.isLoading = false;
            }
        },
        validateForm() {
            this.errors = {};
            const integerValidator = (value) => Number.isInteger(Number(value));
        
            // Extract allowed types from config.js
            const allowedTypes = Object.values(CONFIG.BOOK_TYPE_VALUE);
        
            if (!this.bookData.campus_id) {
                this.errors.campus_id = 'Campus Name is required.';
            } else if (!integerValidator(this.bookData.campus_id)) {
                this.errors.campus_id = 'Campus Name must be an integer.';
            }
        
            if (!this.bookData.category_id) {
                this.errors.category_id = 'Category is required.';
            } else if (!integerValidator(this.bookData.category_id)) {
                this.errors.category_id = 'Category must be an integer.';
            } else if (!this.isValidCategory) {
                this.errors.category_id = 'Selected category is not valid.';
            }
        
            if (!this.bookData.title) {
                this.errors.title = 'Title is required.';
            } else if (this.bookData.title.length > 255) {
                this.errors.title = 'Title must be at most 255 characters.';
            }
        
            if (this.bookData.author.length > 255) {
                this.errors.author = 'Author must be at most 255 characters.';
            }
        
            if (!this.bookData.type) {
                this.errors.type = 'Type is required.';
            } else if (!allowedTypes.includes(Number(this.bookData.type))) {
                this.errors.type = 'Invalid type selected.';
            }
        
            if (this.bookData.type == 2 && this.$refs.fileUploader.files.length > 0) {
                const fileName = this.$refs.fileUploader.files[0].name;
                if (fileName.length > 255) {
                    this.errors.file_name = 'File Name must be at most 255 characters.';
                }
            }
        
            return Object.keys(this.errors).length === 0;
        },    
        getCategoryList() {
            axios.get('category/listforchoose')
                .then(res => {
                    let $defaultSelectOption = [{ id: 0, text: 'Select Category' }];
                    this.categoryArr = $defaultSelectOption.concat(
                        res.data.map(category => ({ id: category.id, text: category.name }))
                    );
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },
        onBookFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const allowedTypes = ['.pdf'];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                if (!allowedTypes.includes(`.${fileExtension}`)) {
                    this.$notification.error('File type not supported!', {
                        timer: 3,
                        position: 'bottomCenter',
                    });
                    return;
                }

                if (file.size > CONFIG.FILE_NAME_MAX_FILE_SIZE) {
                    this.$notification.error(CONFIG.FILE_NAME_MAX_SIZE_ERROR, {
                        timer: 3,
                        position: 'bottomCenter',
                    });
                    return;
                }

                this.bookData.file_name = file.name;
            }
        },
        chooseFile() {
            this.$refs.fileUploader.click();
        },
    },

    mounted() {
        this.getAdminRole();
        this.campusNameAction();
        this.getCategoryList();
    },
};

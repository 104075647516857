import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import {
    required,
    maxLength,
    helpers,
    requiredIf,
    integer
} from "vuelidate/lib/validators";

const inputTypeVal = Object.entries(CONFIG.BOOK_TYPE)
    .filter(([key]) => key !== 'FILE')
    .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});

const inInputType = helpers.withParams(
    { type: "inInputType" },
    value => {
        return Object.values(inputTypeVal).includes(Number(value));
    }
);

const inputTypeArr = Object.entries(CONFIG.BOOK_TYPE_VALUE)
    .filter(([key]) => key !== 'FILE')
    .map(([key, value]) => ({ id: Number(value), text: key }));

export default {
    name: "bookEdit",
    components: {
        MainLayout,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            inputTypeVal: inputTypeVal,
            inputTypeArr: inputTypeArr,
            book: {
                campus: {},
                category_id: null,
                title: '',
                description: '',
                author: '',
                type: '',
                quantity : '',
                file_name: '',
            },
            submitted: false,
            isLoading: false,
            disabled: false,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            invalidFile: false,
            categoryArr: null,
            typeArr: [
                { id: 0, text: 'Select Type' },
                { id: 1, text: 'Physical Book' },
                { id: 2, text: 'Ebook' },
            ],
        };
    },

    validations: {
        book: {
            campus_id: { required },
            category_id: { required },
            title: { required, maxLength: maxLength(255) },
            author: { required, maxLength: maxLength(255) },
            type: { required },
            inInputType,
            file_name: { maxLength: maxLength(255) },
            quantity: {
                integer,
                required: requiredIf(function () {
                    return this.book.type == 1;
                })
            },
        }
    },
    computed: {
        showFileNameField() {
            return Number(this.book.type) === 2;
        },
        showQtyField() {
            return Number(this.book.type) === 1;
        },
        isValidCategory() {
            if (!this.categoryArr || this.categoryArr.length === 0) return false;
            return this.categoryArr.some(
                (category) => Number(category.id) === Number(this.book.category_id)
            );
        },
    },

    methods: {
        getCategoryList() {
            axios.get('category/listforchoose')
                .then(res => {
                    let $defaultSelectOption = [{
                        id: 0,
                        text: 'Select Category'
                    }];
                    this.categoryArr = $defaultSelectOption.concat(
                        res.data.map(category => ({
                            id: category.id,
                            text: category.name
                        }))
                    );
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getDetail() {
            axios.get(`book/detail/${this.$route.params.id}`)
                .then(res => {
                    if (res.data) {
                        this.book = res.data;
                        this.book.author = this.book.author || '';
                        this.book.description = this.book.description || '';
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Book Detail API Error", error);
                });
        },

        async update() {
            this.submitted = true;
            this.$v.$touch();

            if (!this.validateForm()) {
                this.isSubmitting = false;
                this.isLoading = false;
                return;
            }

            const formData = new FormData();
            formData.append('campus_id', this.book.campus_id);
            formData.append('category_id', this.book.category_id);
            formData.append('title', this.book.title);
            formData.append('description', this.book.description);
            formData.append('author', this.book.author);
            formData.append('type', this.book.type);
            formData.append('quantity', this.book.quantity);

            // File handling based on book type
            if (this.book.type == 2) {
                if (this.book.file_name) {
                    const fileInput = this.$refs.fileUploader;
                    if (fileInput && fileInput.files.length > 0) {
                        formData.append('file_name', fileInput.files[0]);
                        formData.append('name', fileInput.files[0].name);
                    }
                }
            } else {
                this.book.file_name = '';
                formData.append('file_name', this.book.file_name);
            }

            this.disabled = true;

            try {
                const res = await axios.post(`book/update/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (res.data.success) {
                    this.$notification.success("Book Updated Successfully", {
                        timer: 3,
                        position: "bottomCenter"
                    });
                    this.$router.push({ name: "bookList" });
                } else {
                    this.disabled = false;
                    this.$notification.error(res.data.message, {
                        timer: 3,
                        position: "bottomCenter"
                    });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
                if (error.response.status === 422) {
                    this.$notification.error("Validation Error: " + error.response.data.message, {
                        timer: 5,
                        position: "bottomCenter"
                    });
                }
            }
        },
        validateForm() {
            this.errors = {};
            const integerValidator = (value) => Number.isInteger(Number(value));
        
            // Extract allowed types from config.js
            const allowedTypes = Object.values(CONFIG.BOOK_TYPE_VALUE);
        
            if (!this.book.campus_id) {
                this.errors.campus_id = 'Campus Name is required.';
            } else if (!integerValidator(this.book.campus_id)) {
                this.errors.campus_id = 'Campus Name must be an integer.';
            }
        
            if (!this.book.category_id) {
                this.errors.category_id = 'Category is required.';
            } else if (!integerValidator(this.book.category_id)) {
                this.errors.category_id = 'Category must be an integer.';
            } else if (!this.isValidCategory) {
                this.errors.category_id = 'Selected category is not valid.';
            }
        
            if (!this.book.title) {
                this.errors.title = 'Title is required.';
            } else if (this.book.title.length > 255) {
                this.errors.title = 'Title must be at most 255 characters.';
            }

            if (this.book.author.length > 255) {
                this.errors.author = 'Author must be at most 255 characters.';
            }

            if (!this.book.type) {
                this.errors.type = 'Type is required.';
            } else if (!allowedTypes.includes(Number(this.book.type))) {
                this.errors.type = 'Invalid type selected.';
            }
        
            if (this.book.type == 2 && this.$refs.fileUploader.files.length > 0) {
                const fileName = this.$refs.fileUploader.files[0].name;
                if (fileName.length > 255) {
                    this.errors.file_name = 'File Name must be at most 255 characters.';
                }
            }
        
            return Object.keys(this.errors).length === 0;
        },   

        chooseFile() {
            this.$refs.fileUploader.click();
        },

        onBookFileChange(event) {       
            const file = event.target.files[0];
            if (file) {
                // File type validation
                const allowedTypes = ['.pdf'];
                const fileExtension = file.name.split('.').pop().toLowerCase(); // Convert extension to lowercase for consistency
                if (!allowedTypes.includes(`.${fileExtension}`)) {
                    this.$notification.error('File type not supported!', {
                        timer: 3,
                        position: 'bottomCenter',
                    });
                    return;
                }
        
                if (file.size > CONFIG.FILE_NAME_MAX_FILE_SIZE) {
                    this.$notification.error(CONFIG.FILE_NAME_MAX_SIZE_ERROR, {
                        timer: 3,
                        position: 'bottomCenter',
                    });
                    return;
                }
        
                this.book.file_name = file.name;
            }
        },

    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
        this.getCategoryList();
    }
};
